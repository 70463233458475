body {
    background-color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.header-custom {
    padding-top: 0;
    background-color: white;
}
/* .navbar-item {
    color: black;
} */
.about-us-wrapper {
    color: black;
}
.references-wrapper {
    color: black;
}
.p-about-us {
    color: black;
    font-size: 17px;
}
.h3-about-us {
    font-weight: 1000;
    font-size: 40px;
}
.navbar-logo {
    background-image: url("../img/logo.png");
    background-size: 100% 100%;
}


@media only screen and (max-width: 768px) { /* Mobile/Tablet */
    .info-text-wrapper {
        margin-top: 40px;
    }
    .about-us-wrapper, .references-wrapper {
        margin: 0 5%;
    }
    .p-about-us {
        width: 90%;
        font-size: 20px;
        margin: 0 5%;
    }
    .h3-about-us, .h3-references {
        font-weight: 800;
        font-size: 35px;
    }
}

@media only screen and (min-width: 768px) { /* Desktop */
    .navbar-logo {
        background-image: url("../img/logo.png");
        background-size: 100% 100%;
        width: 100px;
        height: 40px;
    }
    .logo-list-item {
        float: left !important;
        display: inline !important;
        padding: 0 !important;
    }
    .about-us-wrapper {
        margin-top: 10%;
    }
    .about-us-wrapper, .references-wrapper {
        margin-left: 10%;
    }
    .hide-contact {
        visibility: hidden;
    }
    .h3-about-us, .h3-references {
        font-weight: 800;
        font-size: 35px;
    }
    .p-about-us {
        margin-left: 20px;
    }
}